export default {
    disclaimer: {
        label_default: "I acknowledge and agree that the items I am ordering / receiving are DXC property to be used for DXC work related activities, and as such, DXC reserves the right to request return of the property upon my exit from DXC. For further details, see the <a href='https://dxcportal.sharepoint.com/:b:/r/sites/policy/Shared%20Documents/Information%20Technology/DXC%20IT%20Asset%20Management%20Policy.pdf?csf=1&web=1&e=sW0yCH' target='_blank' rel='noopener'>DXC IT Asset Management Policy</a>.",
        label_type_1: "I confirm that the self-assessment for homeworking was completed accurately and in good faith, and any equipment or furniture provided from a DXC location or ordered through DXC Gear will be used by me for the purpose of my work with DXC. I acknowledge and agree that the items I am ordering / receiving are DXC property to be used for DXC work related activities, and as such, DXC reserves the right to request return of the property upon my exit from DXC. For further details, see the <a href='https://dxcportal.sharepoint.com/:b:/r/sites/policy/Shared%20Documents/Information%20Technology/DXC%20IT%20Asset%20Management%20Policy.pdf?csf=1&web=1&e=sW0yCH' target='_blank' rel='noopener'>DXC IT Asset Management Policy</a>.",
        errors: {
            required: "Please accept terms of use"
        }
    },
    preferred_delivery_date: {
        "Preferred Delivery Date": "Preferred Delivery Date"
    },
    order_note: {
        label: "Order Note",
        placeholder: "Note to approver on this order",
    },
    send_emails_to_oobo_orderer: {
        "OOBO": "Notify me with updates on this order"
    },
    info: {
        label: "Note: To ensure successfully delivery of your items please enter your complete address details including any special instructions. Please double check PIN and Phone  number are complete and correct before submitting your order.  Once submitted, details cannot be changed and orders cannot be canceled."
    },
    info_EGY: {
        label: "All orders in Egypt will be shipped to the local DXC office located at the address below. You will be responsible for picking your order up from the office once it arrives. You will be alerted when your order is ready for pickup. If for some reason you are not able to pick up your order from the DXC office, please submit a support ticket before submitting your order."
    }
}