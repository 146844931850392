import {mapGetters} from "vuex";
import mixin_constraints from "@/mixins/constraints";

export default {
    mixins: [mixin_constraints],
    computed: {
        ...mapGetters({
            cart_loading: "user/cart/loading",
            cart_identifier: "user/cart/id"
        })
    },
    methods: {
        cart_add(item) {

            return this
                .$store
                .dispatch("user/cart/addItem", item[this.cart_identifier])
                .then(() => this.constraints_refreshCartConstraints())
                .then(() => {

                    if (!this.constraints_hasAnyAvailableProducts()) {

                        return this.$router.push({name: "order.submit"});
                    }

                    return true;
                });
        },
        cart_remove(item) {

            return this.$store
                .dispatch("user/cart/removeItem", item[this.cart_identifier])
                .then(() => this.constraints_refreshCartConstraints());
        },
        cart_setQuantity(item, quantity) {

            const currentValue = this
                .$store
                .getters["user/cart/items"]
                .find(cartItem => cartItem[this.cart_identifier] === item[this.cart_identifier])
                .quantity;

            if (currentValue !== quantity) {

                this.$store
                    .dispatch("user/cart/setItemQuantity", {
                        [this.cart_identifier]: item[this.cart_identifier],
                        quantity: parseInt(quantity)
                    })
                    .then(() => this.constraints_refreshCartConstraints());
            }
        },
        cart_find(target) {

            return this.$store.getters["user/cart/items"].find(
                source => source[this.cart_identifier] === target[this.cart_identifier]
            );
        }
    }
}