<template>
    <div class="container">
        <div class="row">
            <div class="col-12 offset-lg-2 col-lg-8 pt-5">
                <div class="form-check text-left py-3">
                    <input type="checkbox" id="acknowledged" class="form-check-input" v-model="model.acknowledged">

                    <label for="acknowledged" class="form-check-label" v-html="message"></label>
                </div>

                <div class="text-center">
                    <button type="button" class="btn btn-primary" :disabled="!model.acknowledged" @click="submit">
                        {{t('submit')}}
                        <spinner v-if="processing"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin_view from "@/mixins/view";

    import PageMessage from "@/components/page/PageMessage";
    import Spinner from "@/components/Spinner";

    export default {
        name: "ViewsAcknowledgement",
        mixins: [mixin_view],
        components: {
            Spinner,
            PageMessage
        },
        data() {

            return {
                t_path: "views.acknowledgement",
                processing: false,

                model: {
                    acknowledged: false
                },

                privacyPolicyUrl: process.env.VUE_APP_PRIVACY_LINK
                    ? process.env.VUE_APP_PRIVACY_LINK
                    : this.$router.resolve({name: "privacy"}).fullPath
            };
        },
        computed: {
            message() {

                return this.t("message", {
                    privacyPolicyUrl: this.privacyPolicyUrl
                })
            },
        },
        methods: {
            submit() {

                this.processing = true;

                this.$store
                    .dispatch("user/setAcknowledgement", this.model.acknowledged)
                    .then(() => {
                        if (this.$route.query.targeturi) {

                            if (
                                0 === this.$route.query.targeturi.indexOf("http://")
                                || 0 === this.$route.query.targeturi.indexOf("https://")
                            ) {

                                return window.location.href = this.$route.query.targeturi;
                            }

                            this.$router.push(this.$router.resolve(this.$route.query.targeturi));
                        } else {

                            this.$router.push({name: "home"})
                        }
                    })
                    .finally(() => this.processing = false);
            }
        },

    }
</script>